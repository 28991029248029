import { AppRouteProps } from "@vdt/component";
import React from "react";
import { HomeRoutes } from "./home/HomeRoutes";
import { TranslationRoutes } from "@vdt/translation";
import { AuthRoutes } from "@vdt/auth";
import { CampagneRoutes } from "@vdt/campagne";
import OrganisationAccessLayer from "container/OrganisationAccessLayer";
import { Outlet } from "react-router-dom";
import { AdminUserManagementRoutes } from "@vdt/user-management";
import ClientLayout from "components/layout/ClientLayout";
import { ClientRoutes } from "./client/ClientRoutes";

const MainLayout = React.lazy(() => import("components/layout/MainLayout"));

export const AppRoutes: Array<AppRouteProps> = [
    {
        path: ":lang",
        title: `VPostal`,
        element: <Outlet />,
        children: [
            {
                path: "",
                element: <ClientLayout />,
                children: [
                    ...ClientRoutes,
                    ...AuthRoutes,
                ],
            },
            {
                path: "",
                element: <OrganisationAccessLayer />,
                children: [
                    {
                        path: "",
                        element: <MainLayout />,
                        children: [
                            ...HomeRoutes,
                            ...TranslationRoutes,
                            ...CampagneRoutes,
                            ...AdminUserManagementRoutes,
                        ],
                    },
                ],
            },
        ],
    },
];

import { useTranslationRoute } from "@vdt/component";
import { Link, Outlet } from "react-router-dom";
import { LeftSideBar } from "./LeftSideBar";

const ClientLayout = () => {
    const tr = useTranslationRoute();

    return (
        <div className="flex w-full bg-secondary text-white font-['Helvetica_Neue']">
            <LeftSideBar className="hidden lg:block" />
            <main className="flex-grow h-screen">
                <header className="bg-primary p-5 flex justify-center lg:hidden">
                    <div className="w-32">
                        <Link to={tr("")}>
                            <img
                                src="/assets/img/spray-logo.png"
                                alt="Logo"
                                className="w-full"
                            />
                        </Link>
                    </div>
                </header>
                <div className="pr-5 pl-5 py-5 lg:pl-[293px]">
                    <Outlet />
                </div>
            </main>
        </div>
    );
};

export default ClientLayout;
